<template>
  <div class="k-9sptci">
    <el-form
        :inline="true"
        class="k-9sptci-header"
        :model="searchForm"
        ref="searchForm"
    >
      <el-form-item label="期榜名称" prop="name">
        <el-input placeholder="期榜名称" v-model="searchForm.name"></el-input>
      </el-form-item>

      <el-form-item label="时间" prop="search_time" :rules="[{
        validator: searchTimeValidator
      }]">
        <div style="display: flex; flex-direction: row">
          <el-date-picker
              v-model="searchForm.start"
              type="date"
              placeholder="选择日期时间">
          </el-date-picker>
          至
          <el-date-picker
              v-model="searchForm.end"
              type="date"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </el-form-item>

      <el-form-item label="状态" prop="status">
        <el-select v-model="searchForm.status" clearable placeholder="请选择">
          <el-option value="-1" label="全部"></el-option>
          <el-option value="0" label="编辑中"></el-option>
          <el-option value="1" label="线上"></el-option>
          <el-option value="2" label="回收站"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary " @click="addSeason">新增</el-button>
      </el-form-item>
      <!--      <el-form-item>-->
      <!--        <el-button @click="handleReset()">重置</el-button>-->
      <!--      </el-form-item>-->
    </el-form>
    <el-table
        :data="seasonTable"
        style="margin-top:20px"
        border
        v-loading="loading"
    >
      <el-table-column
          v-for="item in seasonList"
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
      >
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="330">
        <template slot-scope="scope">
          <perButton :perm="`armory:${type}:saveseason`" @click.native.stop="handleEdit(scope.row)">编辑</perButton>
          <perButton v-if="scope.row.status != 2" :perm="`armory:${type}:saveseason`" @click.native.stop="handleChangeStatus(scope.row)">编辑状态</perButton>
          <popconfirmButton v-if="scope.row.status != 2" :perm="`armory:${type}:saveseason`" content="是否确认删除？" text="删除" @onConfirm="handleDelete(scope.row)" style="margin-left: 10px"></popconfirmButton>
          <popconfirmButton v-else :perm="`armory:${type}:saveseason`" content="是否确认恢复成编辑中？" text="恢复" @onConfirm="handleRecovery(scope.row)" style="margin-left: 10px"></popconfirmButton>
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
          @current-change="handleQuery"
          :current-page.sync="pager.pageNum"
          layout="prev, pager, next"
          :total="pager.total"
      >
      </el-pagination>
    </div>

    <el-dialog
        :title="seasonAction ? '编辑期榜' : '新增期榜'"
        :visible.sync="seasonVisible"
        :close-on-click-modal="false"
    >
      <el-form ref="seasonForm" :model="seasonParams" :rules="seasonRules" label-width="80px">
        <el-form-item label="期榜名称" prop="name">
          <el-input v-model="seasonParams.name" maxlength="8" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="开始时间" prop="start">
          <el-date-picker ref="start" type="date" placeholder="选择开始时间" v-model="seasonParams.start" style="width: 100%;"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="end">
          <el-date-picker ref="end" type="date" placeholder="选择结束时间" v-model="seasonParams.end" style="width: 100%;"></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="confirm">确定</el-button>
          <el-button @click="seasonVisible=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
        :title="'设置期榜状态'"
        :visible.sync="statusVisible"
        width="350px"
        :close-on-click-modal="false"
    >
      <el-form ref="statusForm"
               :model="statusData"
               label-width="80px" >
        <el-form-item label="期榜名称" prop="name">
          <div>{{statusData.name}}</div>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="statusData.status" style="width: 200px">
            <el-option :value="0" label="编辑中"></el-option>
            <el-option :value="1" label="线上"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="changeStatus">确定</el-button>
          <el-button @click="statusVisible=false;">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions} from "vuex";
import perButton from "@/components/perm/perButton"
export default {
  components:{
    perButton
  },
  props: {
    type: {
      type: String,
      default: 'sect'
    }
  },
  data() {
    let self = this;
    return {
      ids: [],
      loading: false,
      pager:{
        total:0,
        pageNum:1
      },
      searchForm: {
        name: "",
        start: "",
        end: "",
        status: ""
      },
      seasonTable: [],
      seasonList: [
        {
          prop: "name",
          label: "期榜名称",
        },
        // {
        //   prop: "year",
        //   label: "年份",
        //   width: 200
        // },
        {
          prop: "start",
          label: "开始时间",
          formatter: this.timeFormat
        },
        {
          prop: "end",
          label: "结束时间",
          formatter: this.timeFormat
        },
        {
          prop: "status",
          label: "状态",
          width: 80,
          formatter: (row) => {
            switch (row.status) {
              case 0:
                return "编辑中";
              case 1:
                return "线上";
              case 2:
                return "回收站";
              default:
                return "";
            }
          },
        },
      ],
      seasonAction: 0,
      seasonParams: {
        _id: "",
        name: "",
        start: "",
        end: "",
        year: -1
      },
      seasonVisible: false,
      seasonRules: {
        name: [
          { required: true, message: '请输入期榜名称', trigger: ['blur', 'change'] },
          { min: 1, max: 8, message: '长度在 ' + 1 + ' 到 8 个字符', trigger: ['blur', 'change'] }
        ],
        start: [
          { required: true, message: '清选择开始时间', trigger: ['blur', 'change'] },
        ],
        end: [
          { required: true, message: '清选择结束时间', trigger: ['blur', 'change'] },
          { validator: this.editTimeValidator, trigger: ['blur', 'change'] }
        ]
      },
      statusVisible: false,
      statusData: {
        name: "",
        status: 0,
        _id: ""
      }
    };
  },
  computed: {
  },
  methods: {
    searchTimeValidator(rule, value, callback) {
      if(this.searchForm.start && this.searchForm.start != '' && this.searchForm.end && this.searchForm.end != '') {
        let end = new Date(this.searchForm.end);
        let start = new Date(this.searchForm.start);
        if(start > end) {
          callback(new Error('截至时间必须大于等于起始时间'));
          return;
        }
      }

      callback();
    },
    editTimeValidator(rule, value, callback) {
      if(this.seasonParams.start && this.seasonParams.start != '' && this.seasonParams.end && this.seasonParams.end != '') {
        let end = new Date(this.seasonParams.end);
        let start = new Date(this.seasonParams.start);
        if(start > end) {
          callback(new Error('结束时间必须大于等于起始时间'));
          return;
        }

        if(start.getFullYear() != end.getFullYear()) {
          callback(new Error('结束时间和起始时间不能跨年'));
          return;
        }

        if (this.seasonParams.year > 0 && start.getFullYear() != this.seasonParams.year) {
          callback(new Error('不能修改期榜的年份'));
          return;
        }
      }

      callback();
    },
    timeFormat(row, column, cellValue, index) {
      if(cellValue && cellValue.length) {
        return new Date(cellValue).format('yyyy/MM/dd');
      } else {
        return cellValue;
      }
    },
    //新增|修改
    async confirm() {
      this.$refs.seasonForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let saveData = {
            ...this.seasonParams
          };

          delete saveData.year;
          const {data, errorCode} = await this.$http.armory.saveSeason(this.type, saveData);

          this.loading = false;
          if (errorCode === "0000") {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.handleQuery();
            this.$refs["seasonForm"].resetFields();
            this.seasonVisible = false;
            this.$bus.$emit("onSeasonChanged", data);
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    addSeason() {
      this.seasonAction = 0;
      this.seasonParams.name = "";
      this.seasonParams.start = "";
      this.seasonParams.end = "";
      this.seasonParams._id = "";
      this.seasonParams.year = -1;
      this.seasonVisible = true;
    },
    async handleEdit(row) {
      this.loading = true;
      let {data, errorCode} = await this.$http.armory.seasonDetail(row._id, this.type);
      this.loading = false;
      if(errorCode != '0000') {
        return;
      }

      this.seasonAction = 1;
      this.seasonVisible = true;
      this.seasonParams.name = data.name;
      this.seasonParams.start = data.start;
      this.seasonParams.end = data.end;
      this.seasonParams._id = data._id;
      this.seasonParams.year = new Date(data.start).getFullYear();
    },
    async handleDelete(row) {
      this.loading = true;
      let {data, errorCode} = await this.$http.armory.deleteSeason(row._id, this.type);
      this.loading = false;

      if(errorCode != '0000') {
        return;
      }

      this.$message({
        type: "success",
        message: "删除成功",
      });

      this.$bus.$emit("onSeasonChanged", data);

      row.status = data.status;
    },
    async handleRecovery(row) {
      this.loading = true;
      let {data, errorCode} = await this.$http.armory.recoverySeason(row._id, this.type);
      this.loading = false;

      if(errorCode != '0000') {
        return;
      }

      this.$message({
        type: "success",
        message: "恢复成功",
      });

      this.$bus.$emit("onSeasonChanged", data);
      row.status = data.status;
    },
    handleChangeStatus(row) {
      this.statusVisible = true;
      this.statusData.status = row.status;
      this.statusData._id = row._id;
      this.statusData.name = row.name;
    },
    searchClick() {
      this.$refs.searchForm.validate((v) => {
      });
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    async handleQuery() {
      const {pageNum} = this.pager;
      const { data, errorCode } = await this.$http.armory.seasonList(this.type, { ...this.searchForm, pageNum });
      if (errorCode != "0000") return;
      this.seasonTable = data.data;
      this.pager = data.pager;
    },
    async changeStatus() {
      this.loading = true;
      let {data, errorCode} = await this.$http.armory.changeSeasonStatus(this.statusData._id, this.statusData.status, this.type);
      this.loading = false;

      if(errorCode != '0000') {
        return;
      }

      this.$message({
        type: "success",
        message: "设置成功",
      });

      this.statusVisible = false;
      let curRow = this.seasonTable.find(r => r._id == data._id);
      if(curRow) {
        curRow.status = data.status;
      }
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async created() {
    this.handleQuery();
  },
  destroyed() {
  }
};
</script>
<style lang="less">
  .k-9sptci-header{
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 10px;
    .el-form-item{
      margin-bottom: 0;
    }

  }
  .k-9sptci-footer{
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px;

  }
</style>
